body {
  font-family: var(--font-monospace);
  background-color: var(--dark-bg);
  color: var(--fg);
}

label {
  display: inline-block;
}

ul {
  padding-left: 0.85rem !important;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--white);
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: var(--white);
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: var(--white);
}

.button {
  @extend .cursor-pointer;
  white-space: pre;
  border-radius: 0.25rem;
  font-weight: 500;
  font-size: 1rem;
  border: 0;
  color: var(--bg);

  &-primary {
    background: var(--yellow);

    &:hover,
    &:disabled {
      background: var(--bright-yellow);
    }
  }

  &-success {
    background: var(--green);

    &:hover,
    &:disabled {
      background: var(--bright-green);
    }
  }
}

.dropdown {
  @extend .absolute;
  z-index: 1000;
  min-width: 8.75rem;
  padding: 0.5rem 0;
  margin: 0;
  font-size: 1rem;
  color: var(--white);
  text-align: left;
  list-style: none;
  background: var(--bright-black);
  background-clip: padding-box;
  border: 1px solid var(--bright-magenta);
  border-radius: 0.25rem;
  inset: 0px auto auto 0px;
  transform: translate(0px, 40px);

  ul,
  li {
    margin: 0;
    list-style: none !important;
    list-style-type: none !important;
  }

  ul > li::marker {
    content: "" !important;
  }

  li {
    @extend .flex;
    @extend .items-center;

    input[type="checkbox"] {
      accent-color: var(--yellow);
    }
  }
}

.search-input {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--white);
  background-color: var(--bright-black);
  background-clip: padding-box;
  border: 1px solid var(--bright-magenta);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:focus {
    color: var(--white);
    background-color: var(--bright-black);
    border-color: var(--bright-magenta);
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(148, 180, 248, 0.25);
  }
}

.section {
  &-search {
    display: flex;
    min-width: 15rem;
  }

  &-table {
    background: var(--bright-black);
    max-height: 35rem;
    overflow: -moz-scrollbars-vertical;
    overflow-y: auto;
  }

  &-table::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: var(--white);
  }

  &-table::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: var(--white);
  }

  &-table::-webkit-scrollbar-thumb {
    background-color: var(--black);
  }
}

.table {
  &-td {
    white-space: nowrap;
  }

  &-th {
    white-space: nowrap;
  }
}

@media screen and (min-width: 720px) {
  .section-table {
    min-width: 40rem;
  }
}

/**
 * Utility Classes
 */
.flex {
  display: flex;
}

.justify-center {
  justify-content: center;
}

.items-center {
  align-items: center;
}

.absolute {
  position: absolute;
}

.ml-0-5 {
  margin-left: 0.5rem !important;
}

.mr-0-5 {
  margin-right: 0.5rem !important;
}

.mt-0-5 {
  margin-top: 0.5rem !important;
}

.mb-0-5 {
  margin-top: 0.5rem !important;
}

.ml-1 {
  margin-left: 1rem !important;
}

.mr-1 {
  margin-right: 1rem !important;
}

.mt-1 {
  margin-top: 1rem !important;
}

.mb-1 {
  margin-top: 1rem !important;
}

.ml-2 {
  margin-left: 2rem !important;
}

.mr-2 {
  margin-right: 2rem !important;
}

.mt-2 {
  margin-top: 2rem !important;
}

.mb-2 {
  margin-top: 2rem !important;
}

.ml-3 {
  margin-left: 3rem !important;
}

.mr-3 {
  margin-right: 3rem !important;
}

.mt-3 {
  margin-top: 3rem !important;
}

.mb-3 {
  margin-top: 3rem !important;
}

.ml-4 {
  margin-left: 4rem !important;
}

.mr-4 {
  margin-right: 4rem !important;
}

.mt-4 {
  margin-top: 4rem !important;
}

.mb-4 {
  margin-top: 4rem !important;
}

.pl-0-5 {
  padding-left: 0.5rem !important;
}

.pr-0-5 {
  padding-right: 0.5rem !important;
}

.pt-0-5 {
  padding-top: 0.5rem !important;
}

.pb-0-5 {
  padding-top: 0.5rem !important;
}

.pl-1 {
  padding-left: 1rem !important;
}

.pr-1 {
  padding-right: 1rem !important;
}

.pt-1 {
  padding-top: 1rem !important;
}

.pb-1 {
  padding-top: 1rem !important;
}

.pl-2 {
  padding-left: 2rem !important;
}

.pr-2 {
  padding-right: 2rem !important;
}

.pt-2 {
  padding-top: 2rem !important;
}

.pb-2 {
  padding-top: 2rem !important;
}

.pl-3 {
  padding-left: 3rem !important;
}

.pr-3 {
  padding-right: 3rem !important;
}

.pt-3 {
  padding-top: 3rem !important;
}

.pb-3 {
  padding-top: 3rem !important;
}

.pl-4 {
  padding-left: 4rem !important;
}

.pr-4 {
  padding-right: 4rem !important;
}

.pt-4 {
  padding-top: 4rem !important;
}

.pb-4 {
  padding-top: 4rem !important;
}

.mx-0-5 {
  margin-inline: 0.5rem !important;
}

.mx-1 {
  margin-inline: 1rem !important;
}

.mx-2 {
  margin-inline: 2rem !important;
}

.mx-3 {
  margin-inline: 3rem !important;
}

.mx-4 {
  margin-inline: 4rem !important;
}

.my-0-5 {
  margin-block: 0.5rem !important;
}

.my-1 {
  margin-block: 1rem !important;
}

.my-2 {
  margin-block: 2rem !important;
}

.my-3 {
  margin-block: 3rem !important;
}

.my-4 {
  margin-block: 4rem !important;
}

.px-0-5 {
  padding-inline: 0.5rem !important;
}

.px-1 {
  padding-inline: 1rem !important;
}

.px-2 {
  padding-inline: 2rem !important;
}

.px-3 {
  padding-inline: 3rem !important;
}

.px-4 {
  padding-inline: 4rem !important;
}

.py-0-5 {
  padding-block: 0.5rem !important;
}

.py-1 {
  padding-block: 1rem !important;
}

.py-2 {
  padding-block: 2rem !important;
}

.py-3 {
  padding-block: 3rem !important;
}

.py-4 {
  padding-block: 4rem !important;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.ml-auto {
  margin-left: auto;
}

.mr-auto {
  margin-right: auto;
}

.cursor-pointer {
  cursor: pointer;
}
