body {
  font-family: var(--font-monospace);
  background-color: var(--dark-bg);
  color: var(--fg);
}

label {
  display: inline-block;
}

ul {
  padding-left: .85rem !important;
}

::-ms-input-placeholder {
  color: var(--white);
  opacity: 1;
}

::placeholder {
  color: var(--white);
  opacity: 1;
}

:-ms-input-placeholder {
  color: var(--white);
}

::-moz-placeholder {
  color: var(--white);
}

.button {
  white-space: pre;
  color: var(--bg);
  border: 0;
  border-radius: .25rem;
  font-size: 1rem;
  font-weight: 500;
}

.button-primary {
  background: var(--yellow);
}

.button-primary:hover, .button-primary:disabled {
  background: var(--bright-yellow);
}

.button-success {
  background: var(--green);
}

.button-success:hover, .button-success:disabled {
  background: var(--bright-green);
}

.dropdown {
  z-index: 1000;
  min-width: 8.75rem;
  color: var(--white);
  text-align: left;
  background: var(--bright-black);
  border: 1px solid var(--bright-magenta);
  background-clip: padding-box;
  border-radius: .25rem;
  margin: 0;
  padding: .5rem 0;
  font-size: 1rem;
  list-style: none;
  top: 0;
  bottom: auto;
  left: 0;
  right: auto;
  transform: translate(0, 40px);
}

.dropdown ul, .dropdown li {
  margin: 0;
  list-style: none !important;
}

.dropdown ul > li::marker {
  content: "" !important;
}

.dropdown li input[type="checkbox"] {
  accent-color: var(--yellow);
}

.search-input {
  width: 100%;
  color: var(--white);
  background-color: var(--bright-black);
  border: 1px solid var(--bright-magenta);
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background-clip: padding-box;
  border-radius: .25rem;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: block;
}

.search-input:focus {
  color: var(--white);
  background-color: var(--bright-black);
  border-color: var(--bright-magenta);
  outline: 0;
  box-shadow: 0 0 0 .25rem rgba(148, 180, 248, .25);
}

.section-search {
  min-width: 15rem;
  display: flex;
}

.section-table {
  background: var(--bright-black);
  max-height: 35rem;
  overflow: -moz-scrollbars-vertical;
  overflow-y: auto;
}

.section-table::-webkit-scrollbar-track {
  background-color: var(--white);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
}

.section-table::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: var(--white);
}

.section-table::-webkit-scrollbar-thumb {
  background-color: var(--black);
}

.table-td, .table-th {
  white-space: nowrap;
}

@media screen and (min-width: 720px) {
  .section-table {
    min-width: 40rem;
  }
}

.flex, .dropdown li {
  display: flex;
}

.justify-center {
  justify-content: center;
}

.items-center, .dropdown li {
  align-items: center;
}

.absolute, .dropdown {
  position: absolute;
}

.ml-0-5 {
  margin-left: .5rem !important;
}

.mr-0-5 {
  margin-right: .5rem !important;
}

.mt-0-5, .mb-0-5 {
  margin-top: .5rem !important;
}

.ml-1 {
  margin-left: 1rem !important;
}

.mr-1 {
  margin-right: 1rem !important;
}

.mt-1, .mb-1 {
  margin-top: 1rem !important;
}

.ml-2 {
  margin-left: 2rem !important;
}

.mr-2 {
  margin-right: 2rem !important;
}

.mt-2, .mb-2 {
  margin-top: 2rem !important;
}

.ml-3 {
  margin-left: 3rem !important;
}

.mr-3 {
  margin-right: 3rem !important;
}

.mt-3, .mb-3 {
  margin-top: 3rem !important;
}

.ml-4 {
  margin-left: 4rem !important;
}

.mr-4 {
  margin-right: 4rem !important;
}

.mt-4, .mb-4 {
  margin-top: 4rem !important;
}

.pl-0-5 {
  padding-left: .5rem !important;
}

.pr-0-5 {
  padding-right: .5rem !important;
}

.pt-0-5, .pb-0-5 {
  padding-top: .5rem !important;
}

.pl-1 {
  padding-left: 1rem !important;
}

.pr-1 {
  padding-right: 1rem !important;
}

.pt-1, .pb-1 {
  padding-top: 1rem !important;
}

.pl-2 {
  padding-left: 2rem !important;
}

.pr-2 {
  padding-right: 2rem !important;
}

.pt-2, .pb-2 {
  padding-top: 2rem !important;
}

.pl-3 {
  padding-left: 3rem !important;
}

.pr-3 {
  padding-right: 3rem !important;
}

.pt-3, .pb-3 {
  padding-top: 3rem !important;
}

.pl-4 {
  padding-left: 4rem !important;
}

.pr-4 {
  padding-right: 4rem !important;
}

.pt-4, .pb-4 {
  padding-top: 4rem !important;
}

.mx-0-5 {
  margin-left: .5rem !important;
  margin-right: .5rem !important;
}

.mx-1 {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}

.mx-2 {
  margin-left: 2rem !important;
  margin-right: 2rem !important;
}

.mx-3 {
  margin-left: 3rem !important;
  margin-right: 3rem !important;
}

.mx-4 {
  margin-left: 4rem !important;
  margin-right: 4rem !important;
}

.my-0-5 {
  margin-top: .5rem !important;
  margin-bottom: .5rem !important;
}

.my-1 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-2 {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}

.my-3 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-4 {
  margin-top: 4rem !important;
  margin-bottom: 4rem !important;
}

.px-0-5 {
  padding-left: .5rem !important;
  padding-right: .5rem !important;
}

.px-1 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.px-2 {
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}

.px-3 {
  padding-left: 3rem !important;
  padding-right: 3rem !important;
}

.px-4 {
  padding-left: 4rem !important;
  padding-right: 4rem !important;
}

.py-0-5 {
  padding-top: .5rem !important;
  padding-bottom: .5rem !important;
}

.py-1 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-2 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}

.py-3 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.py-4 {
  padding-top: 4rem !important;
  padding-bottom: 4rem !important;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.ml-auto {
  margin-left: auto;
}

.mr-auto {
  margin-right: auto;
}

.cursor-pointer, .button {
  cursor: pointer;
}

/*# sourceMappingURL=index.131684ec.css.map */
